<template>
    <div>
        <div class="columns mx-0">
            <div class="column">
                <div class="level p-0 m-0">
                    <div class="level-left">
                        <div class="level-item">Magazinat</div>
                        <input class="input" style="margin: 5px; width: 300px" type="text" placeholder="Kerko..."
                            v-model="global_search_filter" @input="onFilterTextBoxChanged">
                    </div>
                    <div class="level-right">
                        <div class="level">
                            <a @click.prevent="openModal('cities')" class="pr-2 mr-2 border-r ">
                                <small>Qytete</small>
                            </a>
                            <a v-if="am_allowed('transactions.can_create_warehouses')"
                                @click.prevent="create_warehouse">
                                <i class="fa fa-plus"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <ag-grid-vue style="width: 100%; height: 400px" class="ag-theme-balham" :columnDefs="columnDefs"
                    :rowData="rowData.value" :rowSelection="rowSelection" :context="context" :localeText="localeText"
                    :animateRows="true" @grid-ready="onGridReady">
                </ag-grid-vue>
            </div>
            <div class="column is-5" v-if="form_edit || form_create">
                <DaForm v-if="form_create" @submitted="submitWarehouse" :form="form_create" @close="form_create = null" :closes_form="true"></DaForm>
                <DaForm v-if="form_edit" @submitted="submitWarehouse" :form="form_edit" @close="form_edit = null" :closes_form="true"></DaForm>
            </div>
        </div>
    </div>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { AgGridVue } from "ag-grid-vue3";
import DaForm from "@/components/daform/index.vue";
import ButtonsCell from "@/components/buttonscell/ButtonsCell.vue";
import createWarehouse from "../forms/warehouse/create";
import editWarehouse from "../forms/warehouse/edit";
import locale from "@/components/ag-grid/locale/al/al.js"
import WarehousesServices from "@/services/Warehouse";
import permissionMixin from '@/mixins/permissions-mixin.js'
import { $vfm } from 'vue-final-modal'
import socket from "@/socket.js";
import { defineAsyncComponent, onUnmounted, reactive, inject } from "vue"
export default {
mixins: [permissionMixin],
components: {
    AgGridVue,
    DaForm,
},
setup() {
    let rowData = reactive([])
    const toast = inject('toast')
    socket.connect()
    socket.on("welcome", data => {
        console.log("welcome", data)
    })
    socket.on("propagate", data => {
        if(data.action == "create") {
            var a = rowData.value
            a.push(data.data)
            rowData.value = [...a]
            toast.success(
                "Magazina u krijua <i>nga " + data.created_by.name + "</i>"
            );
        }
        if(data.action == "edit"){
            rowData.value = [...rowData.value.map(e => {
                if(e.id == data.data.id) {
                    return data.data
                }
                return e
            })]
            toast.success(
                "Magazina u modifikua <i>nga " + data.created_by.name + "</i>"
            );
        }
    })
    onUnmounted(() => {
        socket.off("welcome");
        socket.off("propagate");
        socket.disconnect();
    })
    return {
        rowData
    }
    },
    beforeMount() {
        this.context = { componentParent: this };
    },
    data() {
        return {
            global_search_filter: "",
            context: null,
            selectedWarehouse: {},
            open_cities: false,
            form_create: null,
            form_edit: null,
            columnDefs: [
                { headerName: "Kategoria", field: "type_id_name", filter: true,
                cellRenderer: function(params) {
                    let r = ""
                    if(params.data.type_id == "EMPTY-STRING" || params.data.type_id == 'undefined')
                        r = `<i class="type_id">[SKA KATEGORI]</i>`
                    else 
                        r = params.data.type_id_name
                    return r
                },
                floatingFilter: true, sortable: true },
                { headerName: "Kodi", field: "code", filter: true, floatingFilter: true, sortable: true, resizable: true },
                { headerName: "Emri", field: "name", filter: true, floatingFilter: true, sortable: true, resizable: true },
                { headerName: "Adresa", field: "location", filter: true, floatingFilter: true, sortable: true, resizable: true },
                { headerName: "Qyteti", field: "city_name", filter: true, floatingFilter: true, sortable: true, resizable: true },
                { headerName: "Pershkrimi", field: "description", filter: true, floatingFilter: true, sortable: true, resizable: true },
                { headerName: "Roli", field: "role", filter: true,
                cellRenderer: function(params) {
                    let r = ""
                    if(params.data.role) {
                        r = params.data.role
                        if(params.data.role == '1')
                            r = "Primar"
                        if(params.data.role == '2')
                            r = "Sekondar"
                    }
                    return r
                },
                floatingFilter: true, sortable: true, resizable: true },
                { headerName: "Personi pergjegjes", field: "user_name", filter: true, floatingFilter: true, sortable: true, resizable: true },
                { headerName: "Njesia e biznesit", field: "unit_name", filter: true, floatingFilter: true, sortable: true, resizable: true },
                { headerName: "Magazina e rezervimit", field: "reservation_warehouse_name",
                cellRenderer: function(params) {
                    let r = ''
                    if(params.data.warehouse_facility == '1') {
                        if(!params.data.reservation_warehouse_name)
                            r = `<i class="reservation_warehouse"> [E PAPERCAKTUAR] </i>`
                        else 
                            r = params.data.reservation_warehouse_name
                    }
                    return r
                },
                filter: true, floatingFilter: true, sortable: true, resizable: true },
                { headerName: "Objekti i magazines", field: "facility_name", filter: true, floatingFilter: true, sortable: true, resizable: true },
                { headerName: "Aktiv", field: "active", filter: true,
                cellRenderer: function(params) {
                    let r = ""
                    if(params.data.active == '0')
                        r = `<i class="fa-regular fa-circle-xmark fa-lg"></i>`
                    else if(params.data.active == '1')
                        r = `<i class="fa-regular fa-circle-check fa-lg"></i>`
                    return r
                },
                floatingFilter: true, sortable: true, resizable: true },
                { headerName: "Rendi i prioritetit", field: "priority_order", filter: true,
                cellRenderer: function(params){
                    let r = ""
                    if(params.data.priority_order == '1')
                        r = "Rendi kryesor"
                    else if(params.data.priority_order == '2')
                        r = "Rendi 2"
                    else if(params.data.priority_order == '3')
                        r = "Rendi 3"
                    return r
                },
                floatingFilter: true, sortable: true, resizable: true },
                {
                    headerName: "Action",
                    cellRenderer: ButtonsCell,
                    cellRendererParams: this.context,
                    resizable: true
                }
            ],
            gridApi: null,
            defaultColDef: {
                flex: 1,
                minWidth: 100
            },
            rowSelection: "single",
            localeText: null
        }
    },

    methods: {
        onFilterTextBoxChanged() {
            this.gridApi.setQuickFilter(this.global_search_filter)
        },
        open_cities_form() {
            this.open_cities = !this.open_cities
        },
        async open(mode, warehouse) {
            if (mode == "1") {
                if (this.am_allowed('transactions.can_edit_warehouses')) {
                    this.form_create = null
                    if (warehouse) {
                        this.form_edit = null
                        setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
                        this.form_edit = { loading: true }
                        this.form_edit = await editWarehouse(warehouse)
                        this.selectedWarehouse = warehouse
                        setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
                    }
                } else {
                    this.$toast.error('Nuk keni te drejta te modifikoni magazine.')
                }
            }
        },
        onGridReady(params) {
            this.gridApi = params.api
            this.gridApi.sizeColumnsToFit();
            this.gridColumnApi = params.columnApi
        },
        async create_warehouse() {
            this.form_edit = null
            setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
            this.form_create = { loading: true }
            this.form_create = await createWarehouse()
            setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
        },
        async submitWarehouse(w) {
            if (this.form_create != null) {
                var notify_text = ""
                let warehouse = await WarehousesServices.createWarehouse(w)
                var a = this.rowData.value
                a.push(warehouse)
                this.rowData.value = [...a]
                socket.emit("propagate", { action: "create", data: warehouse })
                notify_text = "Magazina u krijua me sukses!"
                this.form_create = null
                this.$root.selected_one = {}
            }
            else {
                w.guid = this.selectedWarehouse.guid
                let warehouse = await WarehousesServices.editWarehouse(w)
                this.rowData.value = [...this.rowData.value.map(wa => {
                    if (wa.guid == warehouse.guid) wa = { ...warehouse }
                    return wa
                })]
                socket.emit("propagate", { action: "edit", data: warehouse })
                notify_text = "Magazina u modifikua me sukses!"
                this.form_edit = null
                this.$root.selected_one = {}
            }
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 100);
            this.$toast.success(notify_text)
        },
        openModal(modalName) {
            $vfm.show({
                component: defineAsyncComponent(
                () => import("../components/" + modalName + "-modal.vue")
                ),
            })
        },
    },

    async created() {
        if (!this.am_allowed('transactions.can_see_warehouses')) {
            window.location.href = '/home'
        }
        this.localeText = locale.locale_al()
        this.rowData.value = await WarehousesServices.getWarehouses()
        this.gridApi.sizeColumnsToFit();
    },
    onBeforeDestroy() {
        socket.disconnect();
  },
}
</script>

<style>
 .reservation_warehouse, .type_id {
    color: rgb(235, 8, 8);
 }
</style>
